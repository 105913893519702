import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import React, { Component } from "react";

const loading = () => (
  <div>
    <span>Loading...</span>
  </div>
);

const Layout = React.lazy(() => import("./components/layout"));

class MainRouter extends Component {
  render() {
    return (
      <Router basename="/">
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route path="/" render={(props) => <Layout {...props} />} />
          </Switch>
        </React.Suspense>
      </Router>
    );
  }
}

export default MainRouter;
